



































































































































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { State, Action, namespace } from "vuex-class";
import functions from '../../functions';
import store from '@/store';
import QPdfviewer from '@/components/PDFgenerator/QPdfviewer';
import { EventBus } from "@/event-bus";
import PdfTools from "@/components/PDFgenerator/PdfTools";

@Component({
  components: { QPdfviewer }
})
export default class PrintPreview extends Vue {
  @Prop(String) module;
  @Prop(Object) formData;
  @State("tenantID") tenantID;
  @Action("settings/getModuleSettings") getModuleSettings;
  @Action("settings/setModuleSettings") setModuleSettings;

  template: any = null;
  templates = [];
  drawer = true;
  src = '';
  base64PDF = null;

  mediaPagination = {
    rowsPerPage: 10
  };
  showEditData = false;
  mediaFieldName = '';
  mediaData: Array<any> = [];
  mediaColumns = [
    {
      name: 'stage',
      label: 'Stage',
      field: 'stage',
      align: 'left',
      sortable: true,
    },
    {
      name: 'media',
      label: 'Media',
      field: 'downloadableURL',
      align: 'center',
    },
    {
      name: 'name',
      label: 'Name',
      field: 'fileName',
      align: 'left',
      sortable: true,
    },
    {
      name: 'delete',
      label: 'Delete',
      field: 'delete',
      align: 'left',
      sortable: true,
    }
  ];
  recordMediaToDelete = {};
  showModalDeleteMedia = false;

  async onSaveToAttachment() {
    if (!this.src) {
      return false;
    }
    await this.getOutputPDF(this.template, 'base64');
    const file = {
      name: this.template.name + '.pdf',
      type: 'application/pdf',
      base64PDF: this.base64PDF
    }
    EventBus.$emit('needToSavePDFToAttachment', file);
  }

  getStageName(stage) {
    return [
        this.$t('attachments.headers.mediaBefore'),
        this.$t('attachments.headers.mediaDuring'),
        this.$t('attachments.headers.mediaAfter'),
    ][stage - 1];
  }

  async getMediaData() {
    this.mediaData = [];
    for(const df in this.formData.dynamicSections) {
      if (this.formData.dynamicSections[df].type === 'media') {
        this.mediaFieldName = df;
        this.mediaData = this.formData.data[df].slice();
      }
    }
  }

  onCloseForm() {
    if (this.showEditData) {
      // close edit data
      this.showEditData = false;
      if (this.template && this.template.id) {
        this.getOutputPDF(this.template);
      }
    } else {
      this.$emit("onToolbarPrintPreviewClose");
    }
  }

  get show() {
    return Boolean(this.src) && !this.showEditData;
  }

  chooseTemplate() {
    const selectedPDFTemplateID = this.template && this.template.id ? this.template.id : '';
    this.setModuleSettings({ module: this.module, data: { selectedPDFTemplateID } });
  }

  onEditFormData() {
    this.showEditData = true;
  }

  get showEditDataMedia() {
    return true;
  }

  deleteMediaRecords() {
    const index = this.mediaData.indexOf(this.recordMediaToDelete);
    if (index > -1) {
      this.mediaData.splice(index, 1);
      this.recordMediaToDelete = {};
    }
  }

  @Watch('template')
  setTemplate() {
    if (this.template && this.template.id) {
      this.getOutputPDF(this.template);
    } else {
      this.src = '';
    }
  }

  async getModuleTemplates(): Promise<void> {

    // TODO dev mode
    //functions.useFunctionsEmulator('http://localhost:5001');
    //

    const generatePDF = functions.httpsCallable('GeneratePDF');

    const options = {
      tenant: this.curTenantID,
      module: this.module,
      request: 'getModuleTemplates'
    };

    await generatePDF(options).then((res: any) => {
      const data = res.data;
      this.templates = data;
      //console.log(data);
    }).catch(error => {
      console.log('error: ', error);
      this.templates = [];
    });
  }

  async getOutputPDF(template, output = 'url'): Promise<void> {

    const generatePDF = functions.httpsCallable('GeneratePDF');

    const options = {
      tenant: this.curTenantID,
      request: 'mergeTemplate',
      templateID: template.id,
      fileName: this.getPDFname(template),
      format: 'pdf',
      output: output,
      inputJSON: await this.getFormData()
    };

    //console.log(options.inputJSON);

    await generatePDF(options).then((res: any) => {
      if (output === 'url') {
        this.src = res.data;
      }
      if (output === 'base64') {
        this.base64PDF = res.data;
      }
    }).catch(error => {
      console.log('error: ', error);
      this.templates = [];
    });
  }

  getPDFname(template) {
    return template.name;
  }

  async getFormData() {
    const formData = await PdfTools.getFormDataForPDF(this.formData.module, this.formData.data.ID);
    //console.log(formData);
    const inputJson = PdfTools.getInputJsonForPDF(formData, this.mediaFieldName, (this.mediaData as any));
    //console.log(inputJson);
    return inputJson;
  }

  async setSavedTemplate() {
    const mset = await this.getModuleSettings(this.module);
    if (mset.selectedPDFTemplateID) {
      const sevedTemplate = this.templates.find((t: any) => t.id === mset.selectedPDFTemplateID);
      if (sevedTemplate) {
        this.template = sevedTemplate;
      }
    }
  }

  async mounted() {
    await this.getMediaData();
    await this.getModuleTemplates();
    await this.setSavedTemplate();
  }

  get initialized() {
    return store.state.initialization;
  }

  get curTenantID() {
    return this.tenantID;
  }

}
