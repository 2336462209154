

















































































































































import {Vue, Component, Mixins, Prop} from "vue-property-decorator";
import DynamicField from "@/components/Forms/DynamicField.vue";
import BaseSectionRelatedRecords from '@/components/Base/BaseSectionRelatedRecords.vue';
import BaseSectionAttachment from "@/components/Base/BaseSectionAttachment.vue";
import BaseSectionMedia from "@/components/Base/BaseSectionMedia.vue";
import BaseSectionSubForm from '@/components/Base/BaseSectionSubForm.vue';
import BaseSectionUsage from '@/components/Base/BaseSectionUsage.vue';
import BaseSectionProductLines from '@/components/Base/BaseSectionProductLines.vue';
import BaseSectionEmails from '@/components/Base/BaseSectionEmails.vue';

  @Component({
    components: {
      BaseSectionEmails,
      BaseSectionSubForm, BaseSectionUsage, BaseSectionProductLines,
      DynamicField, BaseSectionRelatedRecords, BaseSectionAttachment, BaseSectionMedia},
  })
  export default class DynamicSection extends Vue {
    @Prop(Object) section;
    @Prop(Object) lineSection;
    @Prop(Object) formData;
    @Prop(Object) showTemplateEmailForm;

    get flabel() {
      return this.section ? this.section.label : '';
    }
    get fname() {
      return this.section ? this.section.name : '';
    }
    get isFirstColumn() {
        return this.section.fields.first.length;
    }
    get isSecondColumn() {
        return this.section.fields.second.length;
    }
    get isFullColumn() {
        return this.section.fields.full.length;
    }
    get firstFields() {
        return this.section.fields.first;
    }
    get secondFields() {
        return this.section.fields.second;
    }
    get fullFields() {
        return this.section.fields.full;
    }
    get sectionType() {
      return this.section.type;
    }
    get relatedModule() {
      return this.section.relatedModule;
    }
    get relatedByField() {
      return this.section.relatedByField;
    }
    get subFormField() {
      return this.section.subFormField ?? this.section.name;
    }
    get subRecordField() {
      return this.section.subRecordField ?? this.section.name;
    }
    get sectionLabel() {
      return this.section.label;
    }
    get sectionColumns() {
      return this.section.columns;
    }
    get customButtons() {
      return this.section.customButtons;
    }
    get lineType() {
      // 'salesLines', 'invoiceLines', 'generalLines', 'purchaseLines'
      return this.section.lineType;
    }
    get sectionEmailField() {
      return this.section.emailField;
    }
    get isHidden() {
      return this.section.hidden;
    }
    get sectionThreadIDField() {
      return this.section.threadIDField;
    }
    saveTheForm(needCallbackAlert = true) {
      this.$emit('saveTheForm', needCallbackAlert);
    }
    addFieldToValidationList(data) {
      this.$emit('addFieldToValidationList', data);
    }
  }
