import store from "../../store";
import firebase from "../../firebase";
import { date } from "quasar";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { State, Getter } from "vuex-class";
import AbModel, { Fields } from "@/components/Models/AbModel";
import debounce from 'debounce';
import { EventBus } from "@/event-bus";

export interface FormData {
  form: string;
  module: string;
  data: Fields;
  callbackAfterSave: object | null | Promise<void>;
  callbackAfterDelete: object | null | Promise<void>;
  callbackAlert: boolean;
  callbackAfterSaveRead: object | null | Promise<void>;
  disabledFields: object | null;
  hiddenFields: object | null;
}

Component.registerHooks([
  'beforeRouteLeave',
]);

@Component({
  components: { },
})
export default class FormMixin extends Vue {
  @Prop(String) ID;
  @Prop(String) action;
  @Prop(Function) callbackAfterSave;
  @Prop([String, Boolean]) isModalForm;
  @Prop([String, Boolean]) isModalDynamicForm;
  @Prop(Object) parentFormData;
  @Prop(String) subFormField;
  @Prop(Object) subFormFieldInfo;
  @Prop(String) dynModuleName;
  @Prop(Object) initParams;

  @State("currentUser") currentUser;
  @State("moneyFormat") moneyFormat;
  @State("percentageFormat") percentageFormat;
  @State("dynamicModules") dynamicModules;

  @Getter("locale") locale;
  @Getter("language") language;
  @Getter("systemVariables") systemVariables;

  module = "";
  dynamicModule = false;
  model = new AbModel();
  savedID = "";

  formData = new class FormDataClass implements FormData {
    form = "";
    module = "";
    data = new Fields();
    callbackAfterSave = null;
    callbackAfterDelete = {};
    callbackAlert = false;
    callbackAfterSaveRead: any = null;
    disabledFields = {};
    hiddenFields = {};
    dropDownValues = {};
    relatedFields = {};
    dynamicFields = {};
    dynamicModule = {};
    dynamicSections = {};
    dynAutoNumbers = {};
    unsubscribeData = null;
    noCloseModalForm = false;
  };

  dropValuesLoaded = false; // all data read from DB
  formDataLoaded = false; // form data loaded
  drawer = null;
  valid = true;
  dropDownValues = {};

  activeSection = 0;
  sections = [{title: "", id: "", icon: "", num: 0}];
  scrollingPage = false;
  unsavedData = false;
  moneyFields = {};
  hoursFields = {};
  percentageFields = {};
  modelDataLoaded = false;
  formMounted = false;
  unsavedWarning = 'Do you really want to leave? You have unsaved changes!';
  notNeedJsonStringifyFields: Array<string> = []; // fields for convertToObject without Json Stringify
  copyFormDataData = {};
  checkFirstRelatedCopyDone = false;
  validationList = {}; // all fields with validate() method

  async initTheForm(): Promise<void> {
    (this.module as any) = this.currentModule;
    this.formData.module = this.currentModule;

    this.modelDataLoaded = false;
    this.formMounted = false;

    await this.initModel();
    let data = {};
    if (this.currentID) {
      (data as any) = await this.getModelData();
      if (data) {
        this.setNewModelFields(data);
        this.resetUnsavedData();
      } else {
        console.log('Record not found - ' + this.currentID + ' module - ' + this.currentModule);
        //TODO not found alert
      }
    } else {
      data = this.getAllFields();
      this.setDefaultValues(data);
      this.localInitForm(data);
    }
    (this.formData.data as any) = data;
    this.copyFormDataData = { ... this.formData.data };
    this.formDataLoaded = true;
  }

  async getModelData() {
    await this.subscribeModelData();
    return await this.model.getByID(this.currentID, this.currentModule);
  }

  async subscribeModelData() {
    if (!this.formData.unsubscribeData) {
      this.formData.unsubscribeData = await this.model.subscribeDoc(this.currentID, this.currentModule, this.onDocumentDataChanged);
    }
  }

  onDocumentDataChanged(data) {
    for(const afield in this.formData.dynAutoNumbers) {
      if (data[afield] && !this.formData.data[afield]) {
        this.formData.data[afield] = data[afield];
        this.resetUnsavedData();
      }
    }
  }

  setNewModelFields(data) {
    if (this.model) {
      const fields = this.getAllFields();
      for (const field in fields) {
        if (!data.hasOwnProperty(field)) {
          data[field] = fields[field];
        }
      }
    }
  }

  saveTheForm(needCallbackAlert = true) {
    if (this.dynamicModule) {
      this.model.setDynModuleName(this.currentModule);
    }
    if (!this.doValidate()) {
      store.state.alertMessage = "not_valid";
      return false;
    }
    this.formData.callbackAlert = true;
    if (!needCallbackAlert) {
      this.formData.callbackAlert = false;
    }
    const dataToSave = this.formData.data;
    this.saveToModel(dataToSave);
    this.resetUnsavedData();
  }

  saveToModel(dataToSave) {

    dataToSave = this.addDataToSaveToModel(dataToSave);

    this.model.save(dataToSave, (ID) => {

      if (this.formData.callbackAlert) {
        store.state.alertMessage = dataToSave.ID ? "update" : "add";
      }
      if (this.callbackAfterSave) {
        this.callbackAfterSave(ID);
      }
      if (this.formData.callbackAfterSave) {
        (this.formData as any).callbackAfterSave(ID);
      }
      if (this.isModalForm) {
        if (this.formData.noCloseModalForm) {
          this.formData.noCloseModalForm = false
        } else {
          if (this.isModalDynamicForm) {
            this.$emit("refreshTheList");
            this.$router.go(-1);
          } else {
            this.$emit("receiveResetShowForm");
          }
        }
      } else {
        if (this.afterSaveRead) {
          this.afterSaveRead(ID);
        }
      }
    }).catch((e) => {
      if (this.formData.callbackAlert) {
        console.log(e);
        store.state.alertMessage = "error";
      }
    });
  }

  addDataToSaveToModel(dataToSave) {
    return dataToSave;
  }

  getAllFields() {
    const mFields = this.model.fields();
    const dFields = this.convertDynamicFields(this.model.dynFields(), this.model.dynSections());
    const result = { ...mFields, ...dFields };
    return result;
  }

  async initModel(): Promise<void> {
    if (this.dynamicModule) {
      this.model.setDynModuleName(this.currentModule);
    }
    if (this.subFormField) {
      this.model.setSubFormField(this.subFormField);
      this.model.setSubFormFieldData(this.subFormFieldInfo);
    }

    await this.model.load(this.currentID);
    this.modelDataLoaded = true;
    this.resetUnsavedData();

    this.formData.relatedFields = this.relatedFields;

    this.formData.disabledFields = this.model.disabledFields;
    this.formData.hiddenFields = this.model.hiddenFields;
    this.formData.dynamicFields = this.model.dynFields();
    this.formData.dynamicModule = this.model.dynModule();
    this.formData.dynamicSections = this.model.dynSections();
    this.formData.dynAutoNumbers = this.model.dynAutoNumbers();

    this.formData.dropDownValues = this.model.dropDownValues;
    this.dropDownValues = this.formData.dropDownValues;
    this.dropValuesLoaded = true;
  }

  getDefValueForType(ftype) {
    const types = {
      text: '',
      checkbox: false,
      number: null,
      date: null,
      datetime: null,
      dropdown: null,
      relatedField: {ID: '', name: ''},
      noType: null,
      subForm: [],
      relatedMultiSelect: [],
      attachment: [],
      media: [],
    };
    return types[ftype];
  }

  convertDynamicFields(fields, sections) {
    const result = {};

    for(const field in fields) {
      const ftype = fields[field].type || 'noType';
      const tVal = this.getDefValueForType(ftype);
      const value = tVal === false ? false
                  : tVal === '' ? ''
                  : tVal || null;

      result[field] = fields[field].defaultValue || value;
    }
    // add sections fields
    for(const sec in sections) {
      if (sections[sec].type === 'usageSection') {
        const fn = sections[sec].subRecordField;
        delete result[fn];
      }
      if (sections[sec].type === 'attachments') {
        result[sec] = [];
      }
      if (sections[sec].type === 'media') {
        result[sec] = [];
      }
    }
    return result;
  }

  localInitForm(data) {
    if (this.initParams) {
      for (const param in this.initParams) {
        if (data.hasOwnProperty(param)) {
          data[param] = this.initParams[param];
        }
      }
    }
    return data;
  }

  doValidate() {
    let error = false;
    for(const field in this.validationList) {
      if (!this.validationList[field]()) {
        error = true;
      }
    }
    return !error;
  }

  async onDeleteForm() {
    this.resetUnsavedData();
    if (this.formData.data.ID) {
      this.model.delete(this.formData.data.ID, this.formData.module)
        .then(() => {
          store.state.alertMessage = "delete";
          this.$router.go(-1);
        });
    }
  }

  setDefaultValues(data) {
    if (this.model && this.model.defaultValues) {
      for (const field in this.model.defaultValues) {
        if (this.model.defaultValues[field].defaultValue) {
          const defValue = this.model.defaultValues[field].defaultValue;
          if (typeof defValue === 'string' && defValue[0] === '$') {
            data[field] = this.systemVariables[defValue];
          } else {
            data[field] = defValue;
          }
        }
      }
    }

    // set Date to now
    for (const field in this.formData.dynamicFields) {
      const val = this.formData.dynamicFields[field];
      if (val.type === 'date' || val.type === 'datetime') {
        if (!data[field]) {
          data[field] = (firebase as any).firestore.Timestamp.now();
        }
      }
    }
  }

  afterSaveRead (id) {
    this.savedID = id;
    if (!this.ID) {
      const uri = window.location.href;
      history.replaceState({}, '', uri.replace('/add', '/edit/' + this.savedID));
    }
    this.initTheForm();
  }

  // by Back
  onBackForm(isModalForm = false) {
    if (this.unsavedData) {
      if (!window.confirm(this.unsavedWarning)) {
        return false;
      }
    }
    if (!isModalForm || this.isModalDynamicForm) {
      this.$emit("refreshTheList");
      this.$router.go(-1);
    } else {
      this.$emit("receiveResetShowForm");
    }
  }

  // by Cancel
  onCloseForm(isModalForm = false) {
    this.resetUnsavedData();
    if (!isModalForm || this.isModalDynamicForm) {
      this.$emit("refreshTheList");
      this.$router.go(-1);
    } else {
      this.$emit("receiveResetShowForm");
    }
  }

  beforeDestroy() {
    this.$emit("receiveResetShowForm");
  }

  emitRelated(records): void {
    records.forEach(r => {
      this.sections.push({
        title: r.label,
        id: r.module,
        icon: "fas fa-arrows-alt-h stroke-transparent",
        num: this.sections.length,
      })
    })
  }

  beforeMount() {
    this.initTheForm();
  }

  get initialized() {
    return this.dropValuesLoaded && this.formDataLoaded;
  }

  cValue(i) {
    if (i && i.name) {
      return i.name;
    }
    if (i && i instanceof Date) {
      return date.formatDate(i, "YYYY-MM-DD HH:mm");
    }
    if (i && typeof i.toDate === "function") {
      return date.formatDate(i.toDate(), "YYYY-MM-DD HH:mm");
    }
    return i;
  }

  convertToObject(data) {
    const saveTs = {};
    const saveFields = {};

    this.notNeedJsonStringifyFields.map((f) => {
      if (data[f]) {
        saveFields[f] = data[f];
      }
    });

    for (const f in data) {
      if (data[f] && typeof data[f].toDate === "function") {
        saveTs[f] = data[f];
      }
    }

    data = JSON.parse(JSON.stringify(data));

    for (const f in saveTs) {
      data[f] = saveTs[f];
    }

    for (const f in saveFields) {
      data[f] = saveFields[f];
    }

    for (const f in data) {
      if (data[f] && data[f].hasOwnProperty('seconds') && data[f].hasOwnProperty('nanoseconds')) {
        data[f] = this.fromObjToTimestamp(data[f]);
      } else {
        if (Array.isArray(data[f])) {
          data[f].map(d => {
            for(const v in d) {
              if (d[v] && d[v].hasOwnProperty('seconds') && d[v].hasOwnProperty('nanoseconds')) {
                d[v] = this.fromObjToTimestamp(d[v]);
              }
            }
          });
        }
      }
    }

    return data;
  }

  fromObjToTimestamp(o) {
    const sec = o.seconds
    const nano = o.nanoseconds;
    return (firebase as any).firestore.Timestamp.fromMillis(sec * 1000 + nano / 1000);
  }

  get relatedFields() {
    return store.state.dynamic;
  }

  created () {
    window.addEventListener('beforeunload', (evt)=>{
      if (this.unsavedData) {
        evt.returnValue = this.unsavedWarning;
        return this.unsavedWarning;
      }
    })
  }

  destroyed() {
      this.resetUnsavedData();
  }

  beforeRouteLeave (to, from , next) {
    if (this.unsavedData) {
      const answer = window.confirm(this.unsavedWarning);
      if (answer) {
        next()
      } else {
        next(false)
      }
    } else next()
  }

  @Watch('formData.data', {deep: true})
  handlerData = debounce(this.checkUnsavedData, 500);

  @Watch('formData.data', {deep: true})
  handlerCopy = debounce(this.checkFirstRelatedCopy, 1000);

  checkUnsavedData() {
    let diff = false;
    for(const field in this.formData.data) {
      if (this.formData.data[field] !== this.copyFormDataData[field]) {
        if (!(!this.formData.data[field] && !this.copyFormDataData[field])) {
          diff = true;
        }
      }
    }
    if (diff) {
      this.unsavedData = this.initialized && this.modelDataLoaded;
      this.copyFormDataData = { ... this.formData.data };
    }
  }

  checkFirstRelatedCopy() {
    // first related copy after form created
    if (!this.formData.data.ID && this.initialized && this.modelDataLoaded && !this.checkFirstRelatedCopyDone) {
      EventBus.$emit('needRelatedCopyForNewForm', { module : this.subFormField || this.currentModule });
      this.checkFirstRelatedCopyDone = true;
    }
  }

  resetUnsavedData() {
    this.unsavedData = false;
    this.copyFormDataData = { ... this.formData.data };
  }

  get currentID() {
    return this.ID || this.savedID;
  }

  isDisabled(field) {
    if (this.model && this.model.disabledFields && this.model.disabledFields[field]) {
      return this.model.disabledFields[field];
    }
    return false;
  }

  isHidden(field) {
    if (this.model && this.model.hiddenFields && this.model.hiddenFields[field]) {
      return this.model.hiddenFields[field];
    }
    return false;
  }

  get currentModule() {
    return this.dynModuleName || this.formData.module;
  }

  get dynModuleData() {
    // TODO from formData
    return this.dynamicModules && this.dynamicModules[this.dynModuleName] ? this.dynamicModules[this.dynModuleName] : {} ;
  }

}
