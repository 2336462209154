



















































































import {Component, Mixins, Watch} from 'vue-property-decorator';
import FormMixin, {FormData} from "@/components/Mixin/FormMixin.ts";
import Toolbar from "@/components/Parts/Toolbar.vue";
import SideNavigationPanel from "@/components/Parts/SideNavigationPanel.vue";
import AbModel, {Fields} from "@/components/Models/AbModel";
import DynamicSection from "@/components/Forms/DynamicSection.vue";
import PrintPreview from '@/components/PDFgenerator/PrintPreview.vue';
import functions from "@/functions";
import store from "@/store";

@Component({
    components: {PrintPreview, Toolbar, SideNavigationPanel, DynamicSection}
})

export default class DynamicForm extends Mixins(FormMixin) {

  model: AbModel = new AbModel();
  dynamicModule = true;
  FormData = new class FormDataClass implements FormData {
    form = "dynamicForm";
    module = '';
    data = new Fields();
    callbackAfterSave = null;
    callbackAfterDelete = {};
    callbackAlert = false;
    callbackAfterSaveRead = null;
    disabledFields = {};
    hiddenFields = {};
    dropDownValues = {};
    relatedFields = {};
    dynamicFields = {};
    dynamicModule = {};
    dynamicSections = {};
  };

  dynFields = {};
  dynSections = {};
  showPrintPreview = false;
  showTemplateEmailForm = {value : false};

  get customButtons() {
    return this.dynModuleData.customButtons ?? {};
  }

  doCustomButtonAction(buttonAction) {
    const actions = buttonAction.actions.sort((a, b) => {
      return a.order - b.order;
    });
    actions.forEach(action => {
      const runAction = functions.httpsCallable('runAction');

      //functions.useEmulator('localhost', 5001);
      const tenantID = store.state.tenantID;
      const parameters = {} as any;
      parameters.ID = this.formData.data.ID;
      parameters.module = this.formData.module;
      const data = {ID: action.ID, type: action.type, tenantID, parameters};
      runAction(data).then(res => {
        console.log(res);
      }).catch(err => {
        console.log(err);
      });
    });
  }

  get showEmailBtn() {
    return Object.values(this.dynSections).find((s: any) => s.type === 'emails');
  }

  onToolbarEmailFormShow(){
    this.showTemplateEmailForm.value = true;
  }

  onToolbarPrintPreview() {
    this.showPrintPreview = !this.showPrintPreview;
  }

  get dynTitle() {
    return this.dynModuleData ? this.dynModuleData.title : null;
  }

  formSectionsLocal() {
    return false;
  }

  get formSections() {
    if (this.formSectionsLocal) {
      const localSections = this.formSectionsLocal();
      if (localSections) {
        return localSections;
      }
    }
    const result: Array<any> = [];
    for (const s in this.dynSections) {
      const sec = {
        id: s,
        name: s,
        ... this.dynSections[s],
        fields: {
          first: [] as any,
          second: [] as any,
          full: [] as any
        },
      };
      if (!sec.order) {
        sec.order = 0;
      }
      const dFields: Array<any> = [];
      for (const f in this.dynFields) {
        const nf = {...{name: f}, ...this.dynFields[f]};
        if (!nf.order) {
          nf.order = 0;
        }
        dFields.push(nf);
      }
      sec.fields.first = dFields.filter(f => f.section === s && f.column === 1).
          sort((a, b) => {
            return a.order - b.order
          });
      sec.fields.second = dFields.filter(
          f => f.section === s && f.column === 2).sort((a, b) => {
        return a.order - b.order
      });
      sec.fields.full = dFields.filter(f => f.section === s && !f.column).
          sort((a, b) => {
            return a.order - b.order
          });
      result.push(sec);
    }
    result.sort((a, b) => {
      return a.order - b.order;
    });

    return result;
  }

  @Watch('formData.dynamicFields', {deep: true})
  handler() {
    this.dynFields = this.formData.dynamicFields;
    this.dynSections = this.formData.dynamicSections;
  }

  get isShowNavBar() {
    return !this.isModalForm;
  }

  // scroll sections
  onScroll({ position }) {
    if (this.scrollingPage !== true) {
      this.updateActiveSection(position);
    }
  }

  updateActiveSection(position) {
    const sections: any = this.formSections;
    // no section in the modal form
    if (this.isModalForm) {
      return true;
    }
    let last;
    for (const i in sections) {
      const section = sections[i];
      const item = this.$refs[section.id][0].$el;
      if (!item) {
        continue;
      }
      if (item.offsetTop >= position + 155) {
        if (!last) {
          last = section.id;
        }
        break;
      } else {
        last = section.id;
      }
    }
    if (last) {
      this.activeSection = last;
    }
  }
  addFieldToValidationList(data) {
    this.validationList[data.name] = data.validate;
  }
  destroyed() {
    if (typeof this.formData.unsubscribeData === "function")  {
      (this.formData.unsubscribeData as any)();
    }
  }
}
