//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { scroll } from "quasar";
const { getScrollTarget, setScrollPosition } = scroll;
export default {
  name: "SideNavigationPanel",
  props: {
    sections: Array,
    dynSections: Array,
    parentRefs: Object,
    activeSection: null,
    moduleName: String,
    moduleInfoHeight: {
        type: String,
        default: '150',
    },
  },
  data() {
    return {
      openInfoModal: false,
      drawer: true,
    };
  },
  methods: {
    isHidden(sec) {
      return sec.hidden;
    },
    toggleInfoModal() {
      this.openInfoModal = !this.openInfoModal;
    },
    scrollToElement(el) {
      const element = this.dynSections ? this.parentRefs[el][0].$el : this.parentRefs[el];
      const target = getScrollTarget(element);
      const offset = element.offsetTop + 30; // -15 because of prettier alignment of the card
      const duration = 150;
      setScrollPosition(target, offset, duration);
    },
    sectionLabel(section) {

      let secName = section.label ?? section.name;
      if (this.moduleName && ! secName) {
        secName = this.$t(this.moduleName + ".headers." + (section.header ? section.header : section.id));
      }
      return secName;
      // return secName + ' ('+ section.order + ')';
    }
  },
  computed: {
  }
};
