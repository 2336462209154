






















































































































































































import {Vue, Component, Mixins, Prop, Watch} from 'vue-property-decorator';
import BaseFieldText from "@/components/Base/BaseFieldText.vue";
import BaseFieldAutoNumber from "@/components/Base/BaseFieldAutoNumber.vue";
import BaseFieldNumber from "@/components/Base/BaseFieldNumber.vue";
import BaseFieldCurrency from "@/components/Base/BaseFieldCurrency.vue";
import BaseFieldPercentage from "@/components/Base/BaseFieldPercentage.vue";
import BaseFieldDate from "@/components/Base/BaseFieldDate.vue";
import BaseFieldDateTime from "@/components/Base/BaseFieldDateTime.vue";
import BaseFieldDropDown from "@/components/Base/BaseFieldDropDown.vue";
import BaseFieldRelated from "@/components/Base/BaseFieldRelated.vue";
import BaseFieldRelatedMultiSelect from "@/components/Base/BaseFieldRelatedMultiSelect.vue";
import BaseFieldCheckbox from "@/components/Base/BaseFieldCheckbox.vue";
import BaseFieldLargeText from "@/components/Base/BaseFieldLargeText.vue";
import BaseFieldDecimal from "@/components/Base/BaseFieldDecimal.vue";
import BaseSectionAttachment from "@/components/Base/BaseSectionAttachment.vue";
import BaseSectionMedia from "@/components/Base/BaseSectionMedia.vue";

@Component({
  components: {BaseFieldText, BaseFieldNumber, BaseFieldCurrency, BaseFieldPercentage,
    BaseFieldDate, BaseFieldDateTime, BaseFieldAutoNumber,
    BaseFieldRelated, BaseFieldDropDown,
    BaseFieldCheckbox, BaseFieldLargeText, BaseFieldDecimal,
    BaseSectionAttachment, BaseSectionMedia, BaseFieldRelatedMultiSelect
  },
})
export default class DynamicField extends Vue {
  @Prop(String) name;
  @Prop(String) type;
  @Prop(String) module;
  @Prop(Object) formData;
  // or
  @Prop(Object) field;
  @Prop(Object) section;
  @Prop(Object) lineSection;

  get flabel() {
      return this.field ? (this.field.label || this.field.name) : this.$t(this.module + '.fields.' + this.name);
  }
  get fname() {
      return this.field ? this.field.name : this.name;
  }
  get ftype() {
      return this.field ? this.field.type : this.type;
  }
  get ftooltip() {
    return this.field ? this.field.tooltip : '';
  }
  get fShowTooltip() {
    return this.field ? this.field.showTooltip : '';
  }
  get showTooltip() {
    return this.fShowTooltip && this.ftooltip;
  }
  get dropDownOptions() {
      return this.formData.dropDownValues[this.fname];
  }
  get relatedModule() {
      return this.field ? this.field.relatedModule : this.module;
  }
  get relatedFieldCopy() {
      return this.field ? this.field.relatedFieldCopy : null;
  }
  get relatedToByQuery() {
      return this.field ? this.field.relatedToByQuery : null;
  }
  get relatedQuery() {
      return this.field ? this.field.query : null;
  }
  get relatedToField() {
    return this.field && this.relatedToByQuery ? this.dynFields[this.relatedToByQuery.relatedTo] : null;
  }
  get dynFields() {
    return this.formData && this.formData.dynamicFields ? this.formData.dynamicFields : {};
  }
  get isHidden() {
    if (this.lineSection && this.lineSection.visibleFields) {
      return this.lineSection.visibleFields.indexOf(this.field.name) === -1;
    }
    return this.field.hidden;
  }
  saveTheForm(needCallbackAlert = true) {
    this.$emit('saveTheForm', needCallbackAlert);
  }
  addFieldToValidationList(data) {
    this.$emit('addFieldToValidationList', data);
  }
}
