//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "Toolbar",
  props: {
    info: {},
    singularName: String,
    pluralName: String,
    unsavedData: Boolean,
    dynTitle: null,
    customButtons: null
  },
  data() {
    return {
      openInfoModal: false,
      deleteFormsModal: false,
    };
  },
  methods: {
    onBack() {
      this.$emit("onToolbarBack");
    },
    onDelete() {
      this.$emit("onToolbarDelete");
    },
    onSave() {
      this.$emit("onToolbarSave");
    },
    onPrintPreview() {
      this.$emit("onToolbarPrintPreview");
    },
    toggleInfoModal() {
      this.openInfoModal = !this.openInfoModal;
    },
    onCustomButton(action) {
      this.$emit('onCustomButton', action);
    }
  },
};
