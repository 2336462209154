import { render, staticRenderFns } from "./DynamicForm.vue?vue&type=template&id=0afb2078&scoped=true&"
import script from "./DynamicForm.vue?vue&type=script&lang=ts&"
export * from "./DynamicForm.vue?vue&type=script&lang=ts&"
import style0 from "./DynamicForm.vue?vue&type=style&index=0&id=0afb2078&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0afb2078",
  null
  
)

export default component.exports
import {QBtn,QTooltip,QIcon,QLayout,QPageContainer,QPageSticky,QCard,QSpace,QDialog,QItem,QTabs,QDrawer,QInput,QSelect} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QBtn,QTooltip,QIcon,QLayout,QPageContainer,QPageSticky,QCard,QSpace,QDialog,QItem,QTabs,QDrawer,QInput,QSelect})
